import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactSimplyCarousel from "react-simply-carousel"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { carousel } from "./pdp.module.css"

export default function Product({ data }) {
  const product = data.product
  const image = getImage(product.frontmatter.thumb)
  const image1 = getImage(product.frontmatter.thumb1)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  return (
    <Layout>
      <Seo title={product.frontmatter.title} />
      <div className="container mx-auto px-4">
        <h1 className="p-6 my-4 heading">{product.frontmatter.title}</h1>
        <div className="flex flex-col md:flex-row mb-4 gap-8">
          {image1 ? (
            <div style={{ position: "relative", maxWidth: 600 }}>
              <ReactSimplyCarousel
                className="max-w-lg"
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={1}
                itemsToScroll={1}
                dotsNav={{ show: true }}
                forwardBtnProps={{
                  //here you can also pass className, or any other button element attributes
                  style: {
                    alignSelf: "center",
                    background: "var(--color-secondary)",
                    border: "none",
                    borderRadius: "50%",
                    color: "white",
                    cursor: "pointer",
                    fontSize: "20px",
                    height: 30,
                    lineHeight: 1,
                    textAlign: "center",
                    width: 30,
                    position: "absolute",
                    top: "calc(50% - 30px)",
                    zIndex: 100,
                    right: 20,
                  },
                  children: <span>{`>`}</span>,
                }}
                backwardBtnProps={{
                  //here you can also pass className, or any other button element attributes
                  style: {
                    alignSelf: "center",
                    background: "var(--color-secondary)",
                    border: "none",
                    borderRadius: "50%",
                    color: "white",
                    cursor: "pointer",
                    fontSize: "20px",
                    height: 30,
                    lineHeight: 1,
                    textAlign: "center",
                    width: 30,
                    position: "absolute",
                    top: "calc(50% - 30px)",
                    zIndex: 10,
                    left: 20,
                  },
                  children: <span>{`<`}</span>,
                }}
                responsiveProps={[
                  {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    minWidth: 300,
                  },
                ]}
                speed={400}
                easing="linear"
              >
                <GatsbyImage
                  image={image}
                  className={carousel}
                  objectFit="fill"
                  alt="producto"
                />
                <GatsbyImage
                  image={image1}
                  className={carousel}
                  objectFit="fill"
                  alt="producto"
                />
              </ReactSimplyCarousel>
            </div>
          ) : (
            <GatsbyImage
              image={image}
              className="max-w-xl mb-4"
              alt="producto"
            />
          )}

          <div style={{ position: "relative" }}>
            <MDXRenderer>{product.body}</MDXRenderer>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    product: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        thumb {
          childImageSharp {
            gatsbyImageData
          }
        }
        thumb1 {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`
